@import url('https://fonts.googleapis.com/css2?family=IM+Fell+Great+Primer+SC&display=swap');

html, body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.App {
  background-image: url('../public/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
  text-align: center;
  overflow: hidden;
  height: 100svh;
  position: relative;
}

.background-layer {
  position: absolute;
  top: 18px;
  bottom: 0;
  width: 100%;
  background-image: url('../public/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}

.top-bar {
  background: rgba(0, 0, 0, 0.95);
  width: 100%;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: rgba(177, 156, 111, 0.85);
}

.header {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  padding: 0;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: 'IM Fell Great Primer SC', serif;
  color: #FADE69;
  line-height: 1;
  font-weight: normal;
}

.header-text {
  width: 100%;
  font-size: 2rem;
  font-weight: normal;
}

.invitation-text {
  font-family: 'IM Fell Great Primer SC', serif;
  color: #FADE69;
  font-size: 12px;
  padding: 0 20px;
  text-align: center;
  max-width: 666px;
  text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.9);
  margin-top: 12px;
}

.audio-controls {
  position: fixed;
  top: calc(18px + 60px);
  left: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
  padding-top: 5px;
}

.audio-controls button {
  background-color: rgba(0, 0, 0, 0.7);
  color: #FADE69;
  padding: 5px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.audio-controls button:hover {
  background-color: rgba(0, 0, 0, 0.9);
  transform: scale(1.1);
}

.audio-controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
}

.footer {
  background: rgba(0, 0, 0, 0.69);
  width: 100%;
  padding: 0;
  font-size: 10px;
  color: rgb(177, 156, 111);
  margin-top: auto;
  text-align: center;
}

.dock {
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 560px;
  height: 94px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dock-menu {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 0 20px;
}

.dock-icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.dock-icon:hover {
  transform: scale(1.2) translateY(-5px);
}

@media (min-width: 601px) and (max-width: 1024px) {
  .header-text {
    font-size: 2rem; 
    font-weight: normal; 
  }
}

.invitation-text {
  width: 75%;
}

.audio-controls {
  position: fixed;
  top: calc(18px + 80px);
  left: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
  padding-top: 5px;
}

@media (max-width: 600px) {
  .header-text {
    font-size: 1.8rem;
    font-weight: normal;
  }

  .invitation-text {
    width: 58%;
    margin-top: 10px; 
  }

  .audio-controls {
    top: calc(18px + 80px);
    left: 10px;
    flex-direction: column;
    gap: 10px;
  }

  .dock {
    width: 85%;
    height: 70px;
    bottom: 40px;
    margin-bottom: 5px;
  }

  .dock-menu {
    padding: 0 4px;
  }

  .dock-icon {
    width: 50px;
    height: 50px;
  }
}